<template>
  <v-menu
    v-model="open"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        v-model="date"
        :label="label"
        :rules="rules"
        :prepend-icon="mdiCalendar"
        readonly
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      :first-day-of-week="datepickerFirstDay"
      :locale="locale"
      @input="open = false"
      no-title
      scrollable
    ></v-date-picker>
  </v-menu>
</template>

<script>
import { mdiCalendar } from '@mdi/js';

export default {
  props: {
    label: String,
    value: String,
    rules: Array,
    showDatepicker: Boolean,
  },

  data: () => ({
    datepickerFirstDay: 1,
    locale: 'hr-HR',
    mdiCalendar,
  }),

  computed: {
    open: {
      get() {
        return this.showDatepicker;
      },
      set(value) {
        this.$emit('update:showDatepicker', value);
      },
    },
    date: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('update:value', value);
      },
    },
  },
};
</script>

