<template>
  <v-autocomplete
    :label="$t('category')"
    :rules="categoryRules"
    :items="categoriesData"
    item-text="name"
    item-value="id"
    v-model="category"
  ></v-autocomplete>
</template>

<script>
import { categoriesData } from '@/mixins/categoriesMixin';

export default {
  props: {
    value: Number,
  },

  mixins: [categoriesData],

  data: function () {
    return {
      categoryRules: [
        (category) => !!category || this.$t('formFieldEmptyRule'),
      ],
    };
  },

  computed: {
    category: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('update:value', value);
      },
    },
  },
};
</script>
