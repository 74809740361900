<template>
  <v-dialog v-model="open" persistent max-width="600px">
    <v-form v-model="formIsValid" @submit.prevent="save">
      <v-card>
        <v-card-text>
          <v-container grid-list-md>
            <v-row>
              <v-col cols="12">
                <v-alert
                  light
                  :value="saveError ? true : false"
                  type="error"
                  transition="scale-transition"
                  >{{ saveError }}</v-alert
                >
              </v-col>
              <v-col cols="12">
                <BaseDatePicker
                  :label="$t('date')"
                  :rules="dateRules"
                  :value.sync="expense.date"
                  :showDatepicker.sync="dateOpen"
                />
              </v-col>
              <v-col cols="12">
                <BaseCategoryPicker :value.sync="expense.category" />
              </v-col>
              <v-col cols="12">
                <BaseTextField :value.sync="expense.value" />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="close">{{ $t('close') }}</v-btn>
          <v-btn color="primary" type="submit" text :disabled="!formIsValid">{{
            $t('save')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';

import BaseCategoryPicker from '@/components/inputs/BaseCategoryPicker';
import BaseDatePicker from '@/components/inputs/BaseDatePicker';
import BaseTextField from '@/components/inputs/BaseTextField';

export default {
  components: {
    BaseCategoryPicker,
    BaseDatePicker,
    BaseTextField,
  },

  props: {
    editedExpense: Object,
    editing: Boolean,
    showExpenseForm: Boolean,
  },

  data: function () {
    return {
      formIsValid: false,
      dateOpen: false,
      expense: {},
      dateRules: [(date) => !!date || this.$t('formFieldEmptyRule')],
    };
  },

  computed: {
    ...mapState({
      saveError: (state) => state.expenses.saveError,
    }),

    open: {
      get() {
        return this.showExpenseForm;
      },
      set(value) {
        this.$emit('update:showExpenseForm', value);
      },
    },
  },

  created() {
    if (this.editing && this.editedExpense) {
      this.expense = { ...this.editedExpense };
    }
  },

  methods: {
    close() {
      this.open = false;
      this.$store.commit('setSaveError', null);
    },

    updateExpense() {
      this.$store
        .dispatch('updateExpense', {
          expense: this.expense,
        })
        .then(() => {
          this.handleSaveError();
        });
    },

    saveExpense() {
      this.$store
        .dispatch('saveExpense', {
          expense: this.expense,
        })
        .then(() => {
          this.handleSaveError();
        });
    },

    handleSaveError() {
      !this.saveError ? (this.open = false) : (this.open = true);
    },

    save() {
      if (this.editing) {
        this.updateExpense();
      } else {
        this.saveExpense();
      }
    },
  },
};
</script>

